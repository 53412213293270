import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import { getImage } from 'gatsby-plugin-image'
import InfoBox from '../components/InfoBox/InfoBox'
import PrimaryButton from '../components/PrimaryButton/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton/SecondaryButton'
import Posts from '../components/Posts/Posts'
import config from '../../config.json'

import '../scss/pages/bank.scss';

export default function Details({pageContext}) {
    const seo = pageContext.data.seo
    const node = pageContext.data

    let logo = false;
    if(Boolean(node.bank.logoBanku)){
        logo = getImage(node.bank.logoBanku.localFile)
    }

    const banksList = useStaticQuery(query).allWpPost.edges;

    function removeHttp(url) {
        return url.replace(/^https?:\/\//, '');
    }

    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={`/banki/${node.slug}`} />
            <div className="page-top-section">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-md-12">
                            {node.bank.naglowek ? (
                                <h1 className="header-h1 text-center mt-15 text-white">{node.bank.naglowek}</h1>
                            ) : (
                                <h1 className="header-h1 text-center mt-15 text-white">Ubezpieczenie do kredytu w banku <br />{node.title}</h1>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bank section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="bank-information">
                                <div className="row">
                                    <div className="col-md-3">
                                        {Boolean(logo) ? (
                                            <div className="logo" style={{ backgroundImage: `url(${logo.images.fallback.src})` }}></div>
                                        ) : (
                                            <div className="logo"></div>
                                        )}
                                    </div>
                                    <div className="col-md-9 bank-information-container">
                                        <h2 className="header-h2 black-text-color title mb-15">{node.bank.tytulOpisu}</h2>
                                        <p className="description mb-0 black-text-color font-light">{node.bank.opis}</p>
                                    </div>
                                </div>
                                <div className="row icons-row">
                                    <div className="col-md-4">
                                        <div className="d-flex">
                                            <div className="icon orange-icon">
                                                <i className="piggy-bank-icon"></i>
                                            </div>
                                            <div className="content saving-content">
                                                <p className="small-title mb-0">Największa oszczędność <br />na polisie</p>
                                                <p className="p-value">{node.bank.najwiekszaOszczednoscNaPolisie}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex">
                                            <div className="icon">
                                                <i className="money-bag-icon"></i>
                                            </div>
                                            <div className="content">
                                                <p className="small-title mb-0">Średnia suma <br />ubezpieczenia</p>
                                                <p>{node.bank.sredniaSumaUbezpieczenia}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex mb-0">
                                            <div className="icon">
                                                <i className="request-icon"></i>
                                            </div>
                                            <div className="content">
                                                <p className="small-title mb-0">Ilość zapytań <br />o polisę</p>
                                                <p>{node.bank.iloscZapytanOPolise}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end btn-group-container">
                                    <div className="btn-group mt-30">
                                        <SecondaryButton link="/ranking-ubezpieczen/" title="Sprawdź ranking ubezpieczeń" />
                                        <PrimaryButton link={config.CALC_LINK} title="Porównaj ofertę" icon="unbalanced-icon" />
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3 className="section-title">Rodzaje ubezpieczeń na życie</h3>
                                        <div className="insurance-types information-container">
                                            {
                                                Object.values(node.bank.rodzajeUbezpieczenNaZycie).map((item) => {
                                                    if(Boolean(item.etykieta) && Boolean(item.nazwaUbezpieczenia)){
                                                        return <div><small>{item.etykieta}</small><p className="mb-0 insurance-name font-bold black-text-color">{item.nazwaUbezpieczenia}</p></div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6 no-left-padding">
                                        <h3 className="section-title">Rodzaje ubezpieczeń majątkowych</h3>
                                        <div className="insurance-types information-container">
                                            {
                                                Object.values(node.bank.rodzajeUbezpieczenMajatkowych).map((item) => {
                                                    if(Boolean(item.etykieta) && Boolean(item.nazwaUbezpieczenia)){
                                                        return <div><small>{item.etykieta}</small><p className="mb-0 insurance-name font-bold black-text-color">{item.nazwaUbezpieczenia}</p></div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="section-title">Informacje o towarzystwie ubezpieczeniowym</h3>
                                    </div>
                                    <div className="col-md-12 informations">
                                        <div>
                                            <div className="information-container">
                                                <small>Kontakt telefoniczny</small>
                                                <p className="font-bold mb-0 black-text-color">{node.bank.numerTelefonu}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="information-container">
                                                <small>Siedziba</small>
                                                <p className="font-bold mb-0 black-text-color">{node.bank.adresSiedziby}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="information-container">
                                                <small>SWIFT</small>
                                                <p className="font-bold mb-0 black-text-color">{node.bank.swift}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="information-container">
                                                <small>IBAN</small>
                                                <p className="font-bold mb-0 black-text-color">{node.bank.iban}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="information-container">
                                                <small>Strona internetowa</small>
                                                <p className="font-bold mb-0 black-text-color">{removeHttp(node.bank.stronaInternetowa)}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="information-container">
                                                <small>Bankowość online</small>
                                                <p className="font-bold mb-0 black-text-color">{removeHttp(node.bank.bankowoscOnline)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InfoBox />
            </div>
            <div className="section-padding bank-post">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="clip black-text-color text-center">{node.bank.zajawka}</p>
                        </div>
                        <div className="col-md-12">
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: node.content }}></div>
                        </div>
                    </div>
                </div>
                <div className="container list-container custom-container">
                    <div className="pl-15 pr-15">
                        {
                            banksList.map((item) => {
                                let logo = null;

                                if(Boolean(item.node.bank.logoBanku)) {
                                    logo = getImage(item.node.bank.logoBanku.localFile)
                                }

                                return <div className="row icons-row list-row">
                                            <div className="col-md-2">
                                                {Boolean(logo) ? (
                                                    <div className="logo" style={{ backgroundImage: `url(${logo.images.fallback.src})` }}></div>
                                                ) : (
                                                    <div className="logo"></div>
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="d-flex">
                                                    <div className="icon orange-icon">
                                                        <i className="piggy-bank-icon"></i>
                                                    </div>
                                                    <div className="content saving-content">
                                                        <small>Największa oszczędność <br />na polisie</small>
                                                        <p>{item.node.bank.najwiekszaOszczednoscNaPolisie}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="d-flex">
                                                    <div className="icon">
                                                        <i className="money-bag-icon"></i>
                                                    </div>
                                                    <div className="content">
                                                        <small>Średnia suma <br />ubezpieczenia</small>
                                                        <p>{item.node.bank.sredniaSumaUbezpieczenia}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <PrimaryButton link={config.CALC_LINK} title="Porównaj oferty" icon="unbalanced-icon" />
                                            </div>
                                        </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <Posts />
        </Layout>
    )
}

const query = graphql`
    {
        allWpPost(
            filter: {categories: {nodes: {elemMatch: {name: {eq: "Bank"}}}}}
        ) {
            edges {
                node {
                    title
                    bank{
                        najwiekszaOszczednoscNaPolisie
                        sredniaSumaUbezpieczenia
                        logoBanku {
                            altText
                            mediaItemUrl
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 600)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`